import * as React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        <title>Rhino Athletics Club</title>
      </Helmet>
      <div class="content">
        <div class="main-left-block">
          <a class="twitter-timeline" data-tweet-limit="1" data-lang="en" data-theme="light" href="https://twitter.com/rhinos_uganda?ref_src=twsrc%5Etfw">Tweets by Rhino Athletics Club</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </div>
        <div class="main-center-block">
          <p>&nbsp;</p>
        </div>
        <div class="main-right-block">
          <h1>&lsquo;&lsquo;Never underestimate the ability of a small group of dedicated people to change the world. Indeed, it is the only thing that ever has.&rsquo;&rsquo;</h1>
          <p>Margaret Mead</p>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage
